<template>
    <a-col class="sectionContent" :span="24">
        <a-row class="body">
            <a-typography-title class="title" :level="2">
                <plus-outlined class="titleIcon"/>
                Escolha seu presente
            </a-typography-title>
        </a-row>
            <div class="gifts">
                <GiftCard
                    subtitle="Presente #1"
                    title="Aula grátis"
                    body="Que tal explorar os segredos por trás desse processo fascinante? Aproveite uma aula especial de preparação de pele, cuidadosamente elaborada para você."
                    link="/pre_aula"
                />

                <GiftCard
                    subtitle="Presente #2(EM BREVE!)"
                    title="Dicas avançadas"
                    body="Dicas que realmente fazem a diferença ao maquiar seus clientes, elevando sua performance e ampliando positivamente as referências que você receberá."
                    link="/"
                />
            </div>
        <a-row class="body">
        <a href="#section-pay">
            <button class="bt-participar">Eu quero</button>
        </a>
        </a-row>
    </a-col>
</template>
<script>
    import GiftCard from '@/assets/widgets/GiftCard.vue';
    import { PlusOutlined } from '@ant-design/icons-vue';

    export default {
        components: {
            GiftCard,
            PlusOutlined,
        }
    }
</script>

<style scoped>
    .sectionContent {
        height: max-content;
        background-color: #0f0f0f;
        min-height: 60vh;
        padding: 5vh 1vw;
    }
    .title {
        color: #f0f0f0;
        padding: 2vh 0;
        text-align: center;
    }
    .body {
        justify-content: center;
        width: 100%;
    }
    .gifts {
        width: 100%;
        justify-content: center;
        gap: 4vw;
        display: flex;
        flex-wrap: wrap;
    }
    .bt-participar {
        margin-top: 3rem;
        background-color: #0F0F0F;
        border: 1px solid #f0f0f0;
        border-radius: 6px;
        color: #FFFFFF;
        padding: .9rem 4rem .9rem 4rem;
        font-size: 1.2rem;
        cursor: pointer;
        opacity: 1;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.349);
    }
    .bt-participar:hover {
        color: #97c986;
        opacity: .96;
        box-shadow: 2px 2px 12px rgba(178, 235, 159, 0.7);
        border: 1px solid #97c986;
    }
    .titleIcon {
        font-size: 1em;
        color:#97c986;
    }
</style>