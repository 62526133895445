<template>
    <a-row>
        <a-col class="sectionContent" :span="24">
            <h1 class="title">
                O que nossos alunos falam...
            </h1>
            <a-col class="content">
                <a-row class="images">
                    <img class="image" src="./images/feedback01.png" alt="imagem feedback"/>
                    <img class="image" src="./images/feedback02.png" alt="imagem feedback2"/>
                    <img class="image" src="./images/feedback03.png" alt="imagem feedback3"/>
                </a-row>
            </a-col>
        </a-col>
    </a-row>
</template>

<script>
    export default {
    
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;800&display=swap');
    .sectionContent {
        background: linear-gradient(to right, #394435 20%, #596b53, #E8DEBA 130%);
        padding: 32px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .title {
        text-align: center;
        font: bold normal 5vh 'Montserrat';
        color: #FFF;
        margin-bottom: 32px;
    }
    .content {
        max-width: max-content;
        justify-content: center;
        overflow: hidden;
        width: max-content;
        max-width: 100vw;
    }

    .images {
        justify-content: center;
        gap: 10vw;
    }
    .image {
        /* max-width: 100%; */
        height: 70vh;
        border-radius: 24px;
    }

    .carrossel-feedback div img{
        justify-items: center;
        margin: auto;
        height: 80vh;
    }
</style>