<template>
    <section class="mainContent">

        <h2>Dúvidas frequentes</h2>

        <div class="itensContent">
            <a-collapse class="contentItem" accordion>
                <a-collapse-panel
                    id="item"
                    key="1"
                    header="Pra quem é o método AV?">
                    <p>Para amantes da beleza principalmente no segmento de maquiagem, que buscam técnicas leves e entender quais os principais erros entre profissionais.</p>
                </a-collapse-panel>
            </a-collapse>

            <a-collapse class="contentItem" accordion>
                <a-collapse-panel
                    id="item"
                    key="2"
                    header="Eu consigo mesmo começando do zero?">
                    <p>Sim! A didática é tratada como passo a passo, sendo explicativa desde qual pincel usar, como usar até o modo de aplicação usado para atingir os resultados de Átila Vinicius.</p>
                </a-collapse-panel>
            </a-collapse>

            <a-collapse class="contentItem" accordion>
                <a-collapse-panel
                    id="item"
                    key="3"
                    header="O que irei aprender?">
                    <p>As principais técnicas de preparação, camuflagens e construção das mais diversas peles encontradas em cotidianos de maquiadores. Como também duas aulas onde são apresentadas as maquiagens mais pedidas pelas clientes de Átila Vinicius.</p>
                </a-collapse-panel>
            </a-collapse>

            <a-collapse class="contentItem" accordion>
                <a-collapse-panel
                    id="item"
                    key="4"
                    header="Tem mentoria online?">
                    <p>Sim! Como um Bônus serão feitos convites exclusivos para os assinantes, onde poderão se aproximar do maquiador especialista. Uma ótima oportunidade para tirar aquela dúvida que te entriga.</p>
                </a-collapse-panel>
            </a-collapse>

            <a-collapse class="contentItem" accordion>
                <a-collapse-panel
                    id="item"
                    key="5"
                    header="Como foram feitas as aulas?">
                    <p>A partir do curso Not To Do, algumas pessoas solicitaram esse conhecimento, para ter principalmente um contato novo sobre as técnicas de preparação de peles, tendo como foco maior, peles negras. Logo, foram extraidas para criação desse curso, na finalidade de oportunizar apenas esse conhecimento.</p>
                </a-collapse-panel>
            </a-collapse>

            <a-collapse class="contentItem" accordion>
                <a-collapse-panel
                    id="item"
                    key="6"
                    header="Se eu não tiver o dinheiro?">
                    <p>Lembre-se: Assim como você, sua concorrente também estará visitando esta página, então é o momento de você saber o que quer e quando vai querer, é opção sua definir qual sua prioridade, assim você saberá se o seu dinheiro poderá ser investido ou não.</p>
                </a-collapse-panel>
            </a-collapse>
        </div>

    </section>
</template>

<script>
import { defineComponent, ref } from 'vue';
export default defineComponent({
  setup() {
    const activeKey = ref([]);

    return {
      activeKey,
    };
  },
});
</script>

<style scoped>
    .mainContent {
        padding: 4rem 0 4rem 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(to right, #394435 20%, #596b53, #E8DEBA 130%);
    }

    .mainContent h2 {
        font-weight: 800;
        font-size: 1.6rem;
        color: #FFFFFF;
        margin-bottom: 2rem;
    }

    .itensContent {
        border: none;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 55%;
    }

    .contentItem {
        border: none;
    }

    #item {
        border-radius: 9px;
        font-weight: bold;
        font-size: .95rem;
        color: black;
        background-color: #FFFFFF;
    }

    @media (max-width: 700px) {
        .itensContent {
            width: 85%;
        }
    }
</style>