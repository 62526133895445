<template>
    <a-col class="sectionContent" :span="24">
            <a-col class="contentImage" :xs="18" :sm="18" :md="10" :lg="8">
                <picture>
                    <img class="image" src="../../assets/images/garantia7dia.png" alt="Garantia 7 dias">
                </picture>
            </a-col>
            <a-col class="contentText" :xs="20" :sm="18" :md="10" :lg="8">
                <h1 class="title">
                    Teste o curso por 7 dias!
                </h1>
                <a-row>
                    <a-typography-text class="text">
                        Se por qualquer motivo você não gostar do curso eu me comprometo em devolver 100% do valor investido em até 7 dias após a compra. Basta você solicitar.
                    </a-typography-text> <br/>
                    <a-typography-text class="text">
                        Ou seja, estou deixando você testar o curso por 7 dias. Se você não gostar devolvo todo o seu dinheiro, sem complicações, como tudo deveria ser.
                    </a-typography-text>
                </a-row>
            </a-col>
        </a-col>
</template>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;800&display=swap');
    .sectionContent {
        gap: 12px;
        padding: 4rem 0;
        height: max-content;
        min-height: 70vh;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .image {
        max-width: 100%;
    }
    .contentImage {
        display: flex;
        justify-content: center;
        align-self: center;
    }
    .contentText {
        align-self: center;
    }
    .title{
        font: normal 800 31px 'Montserrat', 'monospace';
        color: #394435;
    }
    .text {
        font: normal 400 18px 'Montserrat', 'monospace';
        color: #3b3b3b;
    }
</style>