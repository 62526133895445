import { createApp } from 'vue';
import App from './App.vue';
import 'ant-design-vue/dist/reset.css';
import Antd from "ant-design-vue";
import router from './routes';
import {firebaseApp} from "./firebase"
import { analytics } from './firebase';
import VueTheMask from 'vue-the-mask'

const app = createApp(App);
app.use(Antd);
app.use(router);
app.use(firebaseApp);
app.use(analytics);
app.use(VueTheMask);
app.mount('#app')
