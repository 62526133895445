<template>
    <a-col class="contentFooter" :span="24">
            <a-col class="supp" :span="8">
                <a rel="external" class="link" id="linkMail" href="mailto:suporte.perfectskin@atilavinicius.com" target="_blank">
                    SUPORTE
                </a>
            </a-col>

            <a-col class="ciarevit" :span="8">
                <a rel="external" class="link" href="https://www.instagram.com/atilaviniciuss/" target="_blank">
                    Átila Vinícius &copy; 2024
                </a>
            </a-col>

            <a-col class="termos" :span="8">
                <router-link class="link" to="/terms">TERMOS DE USO<br/></router-link>
                <router-link class="link" to="/politics">POLITICAS DE PRIVADICADE</router-link>
            </a-col>
    </a-col>
</template>

<style scoped>
    .contentFooter {
        color: #FFFFFF;
        background-color: black;
        display: flex;
        align-items: center;
        height: 12vh;
    }

    .contentFooter p {
        text-align: center;
    }

    .contentFooter a {
        text-decoration: none;
        color: #FFFFFF;
    }
    .supp {
        justify-content: center;
        text-align: center;
    }
    .ciarevit {
        justify-content: center;
        text-align: center;
    }
    .termos {
        justify-content: center;
        text-align: center;
    }
    .link:hover {
        font-weight: 700;
        color: #aacd9f;
    }
</style>