<template>
    <a-col class="component" :xs="18" :sm="12" :md="16" :lg="12" :xl="10">
        <a-row class="head">
            <a-typography-title :level="2">
                Usando o método
                <br/><text class="ps">Perfect Skin</text>
                <br/>você poderá
            </a-typography-title>
        </a-row>

        <a-row class="list-itens">
            <ul>
                <li id="litem"><img src="../icons/medal.svg"/> Fazer uma pele negra sem acizentar</li>
                <li id="litem"><img src="../icons/medal.svg"/>  Melhorar as coberturas em peles</li>
                <li id="litem"><img src="../icons/medal.svg"/>  Melhor administrar seu tempo</li>
                <li id="litem"><img src="../icons/medal.svg"/>  Aumentar o número de clientes</li>
                <li id="litem"><img src="../icons/medal.svg"/>  Descobrir novas técnicas</li>
                <li id="litem"><img src="../icons/medal.svg"/>  Aumentar o valor do serviço</li>
            </ul>
        </a-row>
        
        <a-col class="livro-bonus" :xs="22" :sm="20" :md="18" :lg="22" :xl="20">
            <p>
                Adquirindo agora você vai
                <br/><text class="bonus">Ganhar o BÔNUS</text>
            </p>

            <button class="bt-livro"> <star-outlined /> Módulo extra</button>
            <p>
                Preparação de peles
                <br/>em características extremas
            </p>
        </a-col>
    </a-col>
</template>

<script>
    import { StarOutlined } from '@ant-design/icons-vue';
    export default {
        components : {
            StarOutlined,
        }
    }
</script>

<style scoped>
    .component {
        padding: 1em;
        /* margin: 2rem 0; */
        /* border: 3px solid orchid; */
    }
    .head {
        justify-content: center;
        color: #e18a8a;
    }
    .ps {
        color:#88694D;
    }
    .list-itens {
        justify-content: center;
    }
    #litem {
        padding: 2px 0;
    }
    .list-itens ul li{
        list-style-type: none;
    }
    .livro-bonus {
        margin: auto;
        padding: 4px;
        /* border: 2px solid black; */
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        border-radius: 8px;
        background-color: #ededed;
    }
    .bonus {
        font-size: 1.3em;
        font-weight: bold;
        color:#3b3b3b;
    }
    .bt-livro {
        background: linear-gradient(270deg, #88A57E 10%, #dbefd4 100%);
        height: 10vh;
        width: 80%;
        border: none;
        border-radius: .8rem;
        font-size: 1.1rem;
        font-weight:bold;
    }
    .bt-garantir {
        gap: 8px;
        display: flex;
        font: normal bold 2rem;
        color: #E8DEBA;
        justify-content: center;
        align-items: center;
        margin: 8px auto;
        border: none;
        background-color: #0F0F0F;
        border-radius: 16px;
        height: 48px;
        width: fit-content;
        padding: 0 16px;
    }
    .bt-garantir:hover {
        color: #b3dea5;
        opacity: .96;
    }
</style>