<template>
    <section class="sectionContent">
        <img src="../../assets/images/perfectskinpng.png" alt="Logo">
        <h1>
            Os segredos essenciais para criar uma pele perfeitamente harmônica que nunca te ensinaram!
        </h1>

        <p>Assista para entender:</p>

        <div class="video-content">
            <a-col :xs="24" :md="18" :lg="16" :xl="12" :xxl="6">
                <iframe
                    ref="ytVideo"
                    id="video"
                    src="https://www.youtube-nocookie.com/embed/SO5ypu2lYJo?start=0&vq=hd1080&autoplay=1&modestbranding=1&rel=0&cc_load_policy=1&iv_load_policy=3&hl=pt-br&fs=0&color=white&controls=0&disablekb=1"
                    height="300"
                    title="Como fazer uma pele harmonica na maquiagem?"
                    frameborder="0">
                </iframe>
            </a-col>
        </div>
        
        <a href="#section-pay">
            <button>
                Garantir vaga
            </button>
        </a>
    </section>
    <counter/>
</template>

<script>
    import CountDown  from '../../material/CountDown.vue'
    export default {
        components: {
            'counter': CountDown
        }
    }
</script>

<style scoped>
    .video-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .sectionContent {
        padding: 3rem 2rem 0 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E3D5C8;
        display: flex;
        flex-direction: column;
    }

    .sectionContent img {
        width: 15rem;
        margin-bottom: 1.5rem;
    }

    .sectionContent h1 {
        font-size: 1.5rem;
        width: 80%;
        text-align: center;
        color: #394435;
        font-weight: 800;
        margin-bottom: 1rem;
    }

    .sectionContent p {
        font-weight: 600;
        width: 80%;
        text-align: center;
    }

    #video {
        width: 100%;
    }

    .sectionContent button {
        background-color: #0F0F0F;
        border: none;
        border-radius: 6px;
        color: #FFFFFF;
        padding: .9rem 4rem .9rem 4rem;
        font-size: 1.2rem;
        cursor: pointer;
        opacity: 1;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.349);
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .sectionContent button:hover {
        opacity: .96;
    }

    @media (max-width: 550px) {

        .sectionContent img {
            width: 10rem;
        }
        .sectionContent h1 {
            font-size: 1.4rem;
            width: 100%
        }

        .sectionContent p{
            width: 100%;
        }

        .sectionContent button{
            padding: .9rem 2.8rem .9rem 2.8rem;
        }
    }
</style>