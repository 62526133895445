<template>
    <section class="sectionContent">
       <div>
           <h2>
               O que você vai encontrar aqui
           </h2>
           <p>
               Descrição sobre os 3 principais pontos <span>abordados no curso</span>
           </p>
       </div>

       <div class="container">
               <div class="contentCards">
                   <LpCard
                   title="Módulo 1"
                   icon="pencil.svg"
                   name="Dificuldades em técnicas versáteis voltadas para atendimentos."
                   :isSelected="selectedModule && selectedModule.id === 1"
                   @click="showModuleContent(1)"
                   />
                   <LpCard
                   title="Módulo 2"
                   icon="tin.svg"
                   name="Dificuldades em técnicas de pele (camuflagens)."
                   :isSelected="selectedModule && selectedModule.id === 2"
                   @click="showModuleContent(2)"
                   />
                   <LpCard
                   title="Módulo 3"
                   icon="star.svg"
                   name="Desmitificando a pele negra."
                   :isSelected="selectedModule && selectedModule.id === 3"
                   @click="showModuleContent(3)"
                   />
           </div>

           <div class="contentCardsScroll">
                <div class="contentScroll">
                    <LpCard
                    title="Módulo 1"
                    icon="pencil.svg"
                    name="Dificuldades em técnicas versáteis voltadas para atendimentos."
                    :isSelected="selectedModule && selectedModule.id === 1"
                    @click="showModuleContent(1)"
                    />
                    <LpCard
                    title="Módulo 2"
                    icon="tin.svg"
                    name="Dificuldades em técnicas de pele (camuflagens)."
                    :isSelected="selectedModule && selectedModule.id === 2"
                    @click="showModuleContent(2)"
                    />
                    <LpCard
                    title="Módulo 3"
                    icon="star.svg"
                    name="Desmitificando a pele negra."
                    :isSelected="selectedModule && selectedModule.id === 3"
                    @click="showModuleContent(3)"
                    />
                </div>
            </div>

           <div class="contentModules" v-if="selectedModule">
           <img alt="banner_module" class="imgAula" :src="selectedModule.src">
               <ul>
                   <li v-for="lesson in selectedModule.lessons" :key="lesson">
                      <img src="../../assets/icons/check.svg" alt="icon"> {{ lesson }}
                   </li>
               </ul>
           </div>
       </div>

       <a href="#section-pay">
           <button>
               Garantir vaga
           </button>
       </a>
   </section>
</template>


<script>



import LpCard from '../../material/LpCard.vue';

export default {
   data() {
       return {
           selectedModule: null,
           modules: [
               {
               id: 1,
               src: require('./images/miniatura-modulo-01.png'),
               lessons: [
                   'Aula 1 - Como fazer a construção de uma maquiagem invisível.', 
                   'Aula 2 - A maquiagem mais pedida em atendimentos (pele fácil para atendimentos tradicionais e Esfumado Atemporal)', 
                   'Aula 3 - A segunda maquiagem mais pedida em atendimento (pele elaborada específica para noivas, formandas, debutantes e Smokey Eye) ',
                   'Aula 4 - Como fazer a construção de uma maquiagem com intensidade sem excessos.'], 
               },
               {
               id: 2,
               src: require('./images/miniatura-modulo-02.png'),
               lessons: [
                   'Aula 1 - Como fazer a construção da maquiagem em peles acneicas',
                   'Aula 2 - Como camuflar melasma em pele madura com aspecto natural.',
                   'Aula 3 - Como fazer a camuflagem de olheiras intensas e pigmentadas.',
                   'Aula 4 - Como maquiar uma pele madura sem excessos. ',
               ], 
               },
               {
               id: 3,
               src: require('./images/miniatura-modulo-03.png'),
               lessons: [
                   'Aula 1 - Como construir uma pele invisível em pele negra, sem acinzentar.',
                   'Aula 2 - Maquiando pele negra com diferentes tonalidades e como harmonizar.',
                   'Aula 3 - Como camuflar discromias intensas na pele negra. ',
                   'Aula 4 - Construindo uma pele negra retinta com harmônia e naturalidade.  ',
               ],
               },
           ],
       };
   },
   components: {
       LpCard,
   },
   created() {
       this.selectedModule = this.modules.find(module => module.id === 1);
   },
   methods: {
       showModuleContent(idCard) {
       this.selectedModule = this.modules.find(module => module.id === idCard);
       console.log(this.selectedModule.src)
       },
   },
   emits: ['click']
};
 
</script>

<style scoped>
   .sectionContent {
       width: 100%;
       padding: 2rem 1rem 2rem 1rem;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       background: linear-gradient(to right, #394435 20%, #596b53, #E8DEBA 130%);
   }

   .sectionContent h2 {
        text-align: center;
        width: 100%;
        margin-top: 4rem;
        margin-bottom: 1rem;
        color: #FFF1BD;
   }

   .sectionContent div p {
       text-align: center;
       color: #FFFFFF;
   }

   .sectionContent p span {
       color: #FFF1BD;
   }

   .container {
        width: 98vw;
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
   }

   .contentCards {
       width: 40%;
       display: flex;
       flex-direction: column;
       gap: 1.5rem;
   }

   .contentCardsScroll {
        width: 100%;
        display: none;
        overflow-x: hidden;
        align-items: center;
   }

   .contentScroll {
        width: 100%;
        padding: 2rem 1rem;
        display: flex;
        gap: 1rem;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

   .contentModules {
       background-color: #0f0f0f46;
       padding: 1rem;
       border: .16rem solid rgba(128, 128, 128, 0.568);
       display: flex;
       flex-direction: column;
       height: 70%;
       max-width: 50%;
       border-radius: 10px;
       box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.349);
    }

   .gifAula {
        width: 100%;
    }

   .contentModules ul {
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       list-style: none;
       margin-top: 2rem;
       gap: 2rem;
    }

   .contentModules ul li {
       color: #FFFFFF;
       font-weight: 600;
       display: flex;
       align-items: flex-start;
       gap: .7rem;
   }

   .sectionContent button {
        background-color: #0F0F0F;
        border: none;
        border-radius: 6px;
        color: #FFFFFF;
        padding: .9rem 4rem .9rem 4rem;
        font-size: 1.2rem;
        cursor: pointer;
        opacity: 1;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.349);
        margin-top: 1rem;
        margin-bottom: 3rem;
   }

   .sectionContent button:hover {
       opacity: .96;
   }


   @media (max-width: 720px) {
       .contentCardsScroll {
           display: flex;
       }

       .contentCards {
        display: none;
       }

       .contentModules {
           max-width: 60%;
       }

       .container {
           padding: 0rem 2rem 0rem 2rem;
           flex-direction: column;
       }
   }

   @media (max-width: 570px) {

       .contentModules {
        max-width: 70%;
       }
   }

   @media (max-width: 420px) {
       .contentModules {
        max-width: 90%;
       }

        .gifAula {
            height: 20vh;
        }
    }

</style>