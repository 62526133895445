<template>
    <a-col class="cardStroke" :xs="16" :md="6" :lg="6" >
        <router-link :to="link">
            <a-row class="subtitle">
                <h2 >
                    <img src="../icons/gift.svg" alt="gift">
                    {{subtitle}}
                </h2>
            </a-row>
            
            <a-row class="title">
                <h1>
                    {{ title }}
                </h1>
            </a-row>
            <a-row class="body">
                <a-tipography-text>
                    {{ body }}
                </a-tipography-text>
            </a-row>
        </router-link>
    </a-col>
</template>

<script>
    export default {
        props:{
            title: String,
            subtitle: String,
            body: String,
            link: String
        }
    }
</script>

<style>
    .cardStroke {
        border: 1px solid #F0F0F0;
        border-radius: 16px;
        min-height: 30vh;
        height: max-content;
        color: #F0F0F0;
        padding: 16px;
    }
    .subtitle {
        gap: 12px;
        display: flex;
        justify-content: flex-start;
        text-align: center;
    }
    .title {
        padding: 4px 0;
    }
    .body {
        padding: 4px 0;
    }
    .cardStroke:hover {
        border: 1px solid #aacd9f;
        box-shadow: 1px 1px 32px #88a57e8c;
        cursor: pointer;
    }

    a {
        color: #f0f0f0;
    }

    a:hover {
        color: #f0f0f0;;
    }
</style>