<template>
    <a-col class="content" :xs="18" :sm="12" :md="16" :lg="16" :xl="12">
      <div v-if="!registeredUser">
        <h1 id="value-old">De <span id="cut">R$2800,00</span></h1>
        <h2 id="value">por <span id="newvalue">12x de R$29,70</span></h2>
        <h3 id="value-total">ou R$297,00 a vista</h3>
  
        <div v-if="authForm" class="content_info">
          <p id="content-text">Você já possui um cadastro neste dispositivo, basta acessar o link para realizar a compra:</p>
          <a class="bt-info" href="https://sun.eduzz.com/mvjmss2s?currency=BRL" target="_blank" rel="noreferrer nofollow">GARANTIR VAGA</a>
        </div>
        <a-form
          v-else
          layout='vertical'
          :model="formState"
          name="basic"
          autocomplete="off"
          @finish="enviarDados"
        >
          <a-form-item
            name="nome"
            :validateStatus="errors.nome ? 'error' : ''"
            :help="errors.nome"
          >
            <a-input v-model:value="formState.nome" placeholder="Informe seu nome e sobrenome:"/>
          </a-form-item>
          <a-form-item
            name="contato"
            :validateStatus="errors.contato ? 'error' : ''"
            :help="errors.contato"
          >
            <a-input v-mask="'## #####-####'" v-model:value="formState.contato" placeholder="Informe seu contato:"/>
          </a-form-item>
          <a-form-item name="check">
            <a-checkbox class="check" v-model:checked="formState.check" >Li e concordo com os Termos</a-checkbox>
          </a-form-item>
          <a-form-item >
            <a-button :class="{ 'enabled-button': formState.check }" class="bt" type="primary" html-type="submit" :disabled="!formState.check">
              <p v-if="!formState.isLoading">GARANTIR VAGA</p>
              <p v-else>Carregando...</p>
            </a-button>
          </a-form-item>
        </a-form>
      </div>
  
      <div class="contentForRedirect" v-else>
        <h2 id="content-title">Cadastro realizado com sucesso!</h2>
        <p id="content-text">Se você não foi redirecionado, clique no botão abaixo:</p>
        <a class="bt-info" href="https://sun.eduzz.com/mvjmss2s?currency=BRL" target="_blank" rel="nofollow">Garantir vaga</a>
      </div>
    </a-col>
  </template>
  
  <script>
  import { db } from '../../firebase/index';
  import { addDoc, collection, serverTimestamp } from 'firebase/firestore/lite';
  
  export default {
  
    data() {
      return {
        authForm: false,
        formState: {
          nome: '',
          contato: '',
          check: false,
          isLoading: false,
        },
        errors: {
          nome: '',
          contato: '',
        },
        registeredUser: false,
      };
    },
  
    mounted() {
      let storageValue = localStorage.getItem('authUser');
      this.authForm = storageValue === 'true';
    },
  
    methods: {
      async enviarDados() {
        const formState = this.formState;
  
        if (formState.nome == '' && formState.contato == '') {
          this.errors.nome = 'Campo obrigatório.';
          this.errors.contato = 'Campo obrigatório.';
          return;
        }
  
        if (!this.validarNome(formState.nome)) {
          return;
        }
  
        if (!this.validarContato(formState.contato)) {
          return;
        }
  
        formState.isLoading = true;
        formState.check = false;
  
        try {
          await addDoc(collection(db, 'data_users'), {
            nome: formState.nome,
            contato: formState.contato,
            produto: 'Perfect Skin',
            data: serverTimestamp(),
            formLocation: 'LandingPage',
          });
  
          formState.nome = '';
          formState.contato = '';
          formState.check = false;
          localStorage.setItem('authUser', true);
  
          this.registeredUser = true;

          setTimeout(() => {
            window.open('https://sun.eduzz.com/mvjmss2s?currency=BRL', '_blank')
          }, 100)
  
          formState.isLoading = false;
        } catch (error) {
          console.error('Erro ao adicionar documento: ', error);
        }
      },
  
      validarNome(nome) {
        const regex = /^[a-zA-Z\u00C0-\u017FçÇ\s'-]+$/;
        if (nome == '') {
          this.errors.nome = 'Campo obrigatório.';
          return false;
        } else if (!regex.test(nome) || nome.length <= 5) {
          this.errors.nome =
            'O nome deve ter pelo menos 6 caracteres e conter apenas letras.';
          return false;
        } else {
          this.errors.nome = '';
          return true;
        }
      },
  
      validarContato(contato) {
        const regex = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:(9\d|[2-9])\d{3})-(\d{4})$/;
  
        if (contato == '') {
          this.errors.contato = 'Campo obrigatório.';
        } else if (!regex.test(contato)) {
          this.errors.contato =
            'O contato deve seguir este padrão: 99 99999-9999.';
          return false;
        } else {
          this.errors.contato = '';
          return true;
        }
      },
    },
  };
  </script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;800&display=swap');
    .content {
        height: fit-content;
        padding: .8rem .5rem;
        background: rgba(57, 68, 53, 0.446);
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.2 );
        backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 8px );
        border-radius: 10px;
        border: 1px solid rgba(95, 95, 95, 0.18);
    }

    .content_info {
        text-align: center;
    }

    .bt-info {
        background-color: #66B649;
        border-radius: 8px;
        border: none;
        padding: .3rem 2rem;
        color: aliceblue;
        font-weight: bolder;
        font-size: 1.1rem;
        cursor: pointer;
        width: 100%;
        text-align: center;
    }

    .bt {
        width: 100%;
    }
    
    .enabled-button {
        background-color: #66B649;
        border-radius: 8px;
        border: none;
        padding: 0rem 2rem;
        color: aliceblue;
        font-weight: bolder;
        font-size: 1.1rem;
        cursor: pointer;
        width: 100%;
    }
    .enabled-button:hover {
        background-color: #57a03c;
        box-shadow: 1px 1px 8px #e3ebe06f;
    }

    #input {
        margin: 8px 0;
        height: 6vh;
    }
    #value-old{
        font: normal 600 1.7em 'Montserrat';
        color: #f0f0f0;
        margin: 0;
        text-align: center;
    }
    #cut {
      color: #e01313;
      text-decoration: line-through;
    }
    #value{
      font: normal bold 2.6em 'Montserrat';
      color: #f0f0f0;
      margin: 0;
      text-align: center;
    }
    #newvalue {
      color: #37c305;
    }
    #value-total {
      font: normal normal 1.2rem 'Montserrat';
      color: aliceblue;
      text-align: center;
    }
    .check {
      color: white;
      padding: 1vh 0;
    }

    #content-text {
        font: normal 600 1.2rem 'Montserrat';
        text-align: center;
        color: #F0F0F0;
    }

    #content-title {
        font: normal bold 1.2rem 'Montserrat';
        text-align: center;
        color: #66B649;
    }

    .contentForRedirect {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
</style>
