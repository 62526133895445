<template>
    <a-col id="section-pay" :span="24" class="sectionContent">
        <a-row class="content">
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="book">
                <BookBonus/>
            </a-col>
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="pay">
                <PayComponent/>
            </a-col>
        </a-row>
    </a-col>
</template>
<script >
    import BookBonus from '@/assets/widgets/BookBonus.vue';
    import PayComponent from '@/assets/widgets/PayComponent.vue';
    export default {
        components: {
            BookBonus,
            PayComponent,
        },

    }
</script>

<style scoped>
    .sectionContent {
        height: max-content;
    }
    .content {
        /* display: flex; */
        flex-wrap: wrap;
    }
    .book {
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding-right: 32px;   */
    }
    .pay {
        background-image: url('../../assets/images/AV-TALINE.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: max-content;
        min-height: 80vh;
        justify-content: center;
        align-items: center;
        display: flex;
    }
</style>