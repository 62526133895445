<template>
    <div :class="{ cardContent: true, selected: isSelected }" @click="handleClick">
        <div class="container">
            <div class="headerCard">
                <img :src="iconPath" alt="icon">
                <span>{{title}}</span>
            </div>
            <p id="moduleName">{{name}}</p>
        </div>
    </div>
</template>

<script>

export default {
    props: {
    title: String,
    icon: String,
    name: String,
    isSelected: Boolean
  },
  computed: {
    iconPath() {
      return require(`@/assets/icons/${this.icon}`);
    },
  },
  methods: {
    handleClick() {
    this.$emit('click', this.title)
    }
  }
};
</script>


<style scoped>
    .cardContent {
        position: relative;
        color: #e2e2e2;
        display: flex;
        border: 1px solid #f2f2f2;
        flex-direction: column;
        border-radius: 8px;
        padding: 1rem 1rem 0rem 1rem;
        width: 70%;
        cursor: pointer;
    }

    #moduleName  {
        padding-top: 1.5rem;
    }

    .headerCard {
        position: absolute;
        padding-top: .5rem;
        top: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .6rem;
        padding-bottom: 1rem;
    }

    .headerCard img {
        padding: .3rem;
        height: 24px;
        color: black;
        background-color: #1e1e1e;
        border-radius: 4px;
    }

    .cardContent span {
        text-align: left;
        white-space: nowrap;
    }

    .selected {
        background-color: #1b2617; 
        scale: 1.05;
        transition: .2s ease-in-out;
    }

    @media (max-width: 720px) {
        .cardContent {
            width: 80%;
        }
    }

    @media (max-width: 570px) {

        .cardContent {
            padding: 1rem 1.3rem 0rem 1rem;
        }
        .cardContent {
            width: 75%;
        }
    }

    @media (max-width: 470px) {
        .cardContent {
            width: 80%;
        }
    }

    @media (max-width: 390px) {
        .cardContent {
            width: 100%;
        }
    }

    @media (max-width: 320px) {
        .cardContent {
            font-size: 12px;
        }

        #moduleName {
            padding-top: 1rem;
            padding-bottom: 0.5rem;
        }
    }
</style>