<template>
    <a-col class="sectionContent" :span="24">
        <a-col class="content" :xs="20" :sm="18" :md="12" :lg="12" :xl="16">

            <a-row class="texts" >
                <usergroup-add-outlined id="user-icon"/>
                <t class="body">Somamos mais de <t class="mil">1.120</t> alunos no método AV</t>
            </a-row>

            <a-col :span="24">
                <a-carousel
                    autoplay
                    dot-position=top
                    class="carrossel-alunos"     
                    >
                    <div><img class="images" src="../../assets/images/MODULES-BANNER-01.jpg" alt=""></div>
                    <div><img class="images" src="../../assets/images/MODULES-BANNER-02.jpg" alt=""></div>
                    <div><img class="images" src="../../assets/images/MODULES-BANNER-03.jpg" alt=""></div>
                </a-carousel>
            </a-col>

            <a-row class="bt-action">
                <a href="#section-pay">
                    <button class="bt-participar">
                        Quero ser aluno
                    </button>
                </a>
            </a-row>

        </a-col>
    </a-col>
</template>

<script>
    import { UsergroupAddOutlined } from '@ant-design/icons-vue';

    export default {
        components: {
            UsergroupAddOutlined,
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;800&display=swap');
    .sectionContent {
        background-color: #0f0f0f;
        color: #f0f0f0;
        display: flex;
        justify-content: center;
    }
    .content {
        align-items: center;
        justify-content: center;
        min-height: 60vh;
        height: max-content;
    }
    .texts {
        justify-content: center;
        padding: 5vh 0;
    }
    .bt-action {
        justify-content: center;
        padding-bottom: 5vh;
    }
    .body {
        align-self: center;
        font: bold normal 5vh 'Montserrat';
    }
    #user-icon {
        font-size: 20vh;
        color: #97c986;
    }
    .mil {
        font: bold normal 6vh 'Montserrat';
        -webkit-text-fill-color: black; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #97c986;
    }
    .bt-participar {
        margin-top: 3rem;
        background-color: #0F0F0F;
        border: 1px solid #f0f0f0;
        border-radius: 6px;
        font-size: 1.2rem;
        color: #FFFFFF;
        padding: .9rem 4rem .9rem 4rem;
        cursor: pointer;
        opacity: 1;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.349);
    }
    .bt-participar:hover {
        opacity: .96;
        box-shadow: 2px 2px 12px rgba(178, 235, 159, 0.7);
        border: 1px solid #97c986;
    }
    .images{
        height: 70vh;
        width: auto;
        justify-items: center;
        margin: auto;
    }

    :deep(.slick-slide) {
        text-align: center;
        height: max-content;
        background: #0f0f0f;
        overflow: hidden;
    }
    :deep(.slick-slide h3) {
        color: #fff;
    }
</style>