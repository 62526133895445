<template>
    <a-col class="sectionContent" :span="24">
        <a-col class="title" :span="22">
            <h1>
                Por que escolher o Perfect Skin?
            </h1>
            <p>
                Já estive no seu lugar e conheço suas dores! Por isso, desenvolvi <br/>esse método que vai te ajudar a evoluir muito mais rápido!
            </p>
        </a-col>
        <a-row class="content">
            <BadgeCard 
                icon="certificado.svg"
                title="Emissão de certificado"
                text="Ao concluir o curso por completo você receberá um certificado digital declarando que participou do curso Perfect Skin."
            />
            <BadgeCard 
                icon="espelho.svg" 
                title="Teoria e prática" 
                text="O curso ensina as técnicas tanto por meio de explicações quanto de demonstrações."
            />
            <BadgeCard 
                icon="horas.svg" 
                title="Mais de 20h de conteúdo" 
                text="O curso tem uma carga horária de 12h, divididas em 3 módulos. Você pode fazer o curso no seu próprio ritmo, acessando a qualquer momento."
            />
            <BadgeCard 
                icon="confirma.svg" 
                title="5 anos de mercado" 
                text="Mentor com mais de 5 anos de experiencia com um método validado."
            />
        </a-row>
    </a-col>
</template>
<script>
    import BadgeCard from '@/assets/widgets/BadgeCard.vue';
    export default{
        components: {
            BadgeCard,
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;800&display=swap');
    .sectionContent {
        background: linear-gradient(to right, #394435 20%, #596b53, #E8DEBA 130%);
        height: max-content;
        padding: 15vh 0;
    }
    .title {
        text-align: center;
        color: #f0f0f0;
        margin: auto;
        justify-content: center;
    }
    .title h1{
        font: normal bold 2rem 'Montserrat', monospace;
    }
    .title p {
        font: normal 400 1.2rem 'Montserrat', monospace;
    }
    .content {
        width: 100%;
        justify-content: center;
        gap: 2vh 5vw;
    }
</style>