<template>
    <div>
        <header>
          <LpPresentation/>
        </header>
        
        <main>
          <LpModules/>
          <LpAlunos/>
          <LpFeedbacks/>
          <LpPortfolio/>
          <LpPerfectSkin/>
          <LpPay/>
          <LpGift/>
          <LpTesteCurso/>
          <LpDoubts/>
        </main>

        <footer>
          <LpFooter/>
        </footer>
    </div>
  
  </template>
  <script>
  import LpModules from '../components/LpModules/LpModules.vue'
  import LpPortfolio from '../components/LpPortfolio/LpPortfolio.vue'
  import LpPresentation from '../components/LpPresentation/LpPresentation.vue'
  import LpAlunos from '../components/LpAlunos/LpAlunos.vue';
  import LpFeedbacks from '../components/LpFeedbacks/LpFeedbacks.vue';
  import LpFooter from '../components/LpFooter/LpFooter.vue'
  import LpDoubts from '../components/LpDoubts/LpDoubts.vue'
  import LpPerfectSkin from '../components/LpPerfectSkin/LpPerfectSkin.vue';
  import LpTesteCurso from '../components/LpTesteCurso/LpTesteCurso.vue';
  import LpPay from '../components/LpPay/LpPay.vue';
  import LpGift from '../components/LpGift/LpGift.vue';
  
  
  export default {
      name: 'LandingPage',
      components: {
        LpPresentation,
        LpModules,
        LpPortfolio,
        LpAlunos,
        LpFeedbacks,
        LpPerfectSkin,
        LpPay,
        LpTesteCurso,
        LpFooter,
        LpDoubts,
        LpGift,
    },
    created() {
      document.title = 'Perfect Skin - Átila Vinicius';
    }
  }
  </script>
  
  <style>
  
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;800&display=swap');
  
  #app {
    font-family: 'Montserrat', sans-serif;  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  </style>
  