<template>
    <a-row
    align-items="center"
    class="content-title"
    justify="center"
    >
        <a-col>
            <h1 class="titlee" id="content-title">
                Compre agora com um desconto especial
            </h1>
            <h3 class="cup">garanta <span id="desconto">50% de desconto</span> no botão abaixo.</h3>
        </a-col>
    </a-row>
    <a-row
        justify="center"
        align-items="center"
        class="timer-content"
    >
        <a-col :xs="4" :sm="3" :md="3" :lg="2" :xl="1" class="timer">
            <p class="timer-text">dias</p>
            <p class="timer-item" id="no-time">
                {{  displayDays }}
            </p>
        </a-col>

        <a-col :xs="4" :sm="3" :md="3" :lg="2" :xl="1" class="timer">
            <p class="timer-text">horas</p>
            <p class="timer-item" id="no-time">
                {{ displayHour }}
            </p>
        </a-col>

        <a-col :xs="4" :sm="3" :md="3" :lg="2" :xl="1" class="timer">
            <p class="timer-text">minutos</p>
            <p class="timer-item">
                {{  displayMin }}
            </p>
        </a-col>

        <a-col :xs="4" :sm="3" :md="3" :lg="2" :xl="1" class="timer">
            <p class="timer-text">segundos</p>
            <p class="timer-item">
                {{  displaySeconds}}
            </p>
        </a-col>

        <a-row justify="justify" class="butContent">
            <a-col>
                <a class="butClick" href="#section-pay" v-on:click="copiarCupom">Garantir cupom</a>
            </a-col>
        </a-row>

        <!-- <a-col :xs="8" :sm="5" :md="4" :lg="4" :xl="3">
            <a class="butClick" href="#section-pay" v-on:click="copiarCupom">Garantir cupom</a>
        </a-col> -->
    </a-row>
</template>

<script>
import { notification } from 'ant-design-vue';
    export default {
        data: () => ({
            displayDays: 0,
            displayHour: 0,
            displayMin: 0,
            displaySeconds: 0,
            timerRunning: false,
            timeStamp: 900
        }),
        mounted() {
            this.showRemainingTime();
        },
        methods: {
            showRemainingTime() {
                this.timerRunning = true;
                this.timerInterval = setInterval(() => {

                    if (this.timeStamp > 0) {
                        this.timeStamp -= 1;
                        // this.openNotification();
                        // return
                    } else {
                        this.stopTimer();
                    }

                    const minutes = Math.floor(this.timeStamp / 60);
                    const seconds = Math.floor(this.timeStamp % 60);

                    this.displayMin = this.formatNumb(minutes);
                    this.displaySeconds = this.formatNumb(seconds);
                }, 1000 );
            },
            stopTimer() {
                this.timerRunning = false;
                // clearInterval(this.timerInterval);
                this.timeStamp = 900; // Reset to 15 minutes
            },

            formatNumb: (numb) => ( numb < 10 ? '0' + numb : numb),

            openNotification() {
                notification.open({
                    message: "😍 Copiado para área de transferência!",
                    description: "cupom de 50% de desconto: MAISPELE",
                    duration: 5,
                });
            },

            copiarCupom() {
                
                const cupomTexto = "MAISPELE";

                // Criar um elemento input para armazenar o texto temporariamente
                const inputElement = document.createElement("input");
                inputElement.value = cupomTexto;
                document.body.appendChild(inputElement);

                // Selecionar o texto no input
                inputElement.select();
                inputElement.setSelectionRange(0, 99999); // Para dispositivos móveis

                // Executar o comando de cópia
                document.execCommand("copy");

                // Remover o elemento input temporário
                document.body.removeChild(inputElement);

                // Exibir alguma mensagem ou realizar outras ações após a cópia
                console.log("Texto copiado para a área de transferência: ", cupomTexto);
                
                this.openNotification();
            }
        }
    };
</script>

<style>
    .timer-content {
        background-color: #ffc107;
        align-items: center;
        padding: 12px 0;
    }
    .content-title {
        background-color: #1a1a1a;
    }
    #content-title {
        color: #ffc107;
    }
    .cup {
        font-size: 1rem;
        text-align: center;
        color: #F0F0F0;
        font-weight: 600;
    }
    #desconto {
        color: rgb(255, 36, 36);
    }
    .titlee {
        font-size: 1.2rem;
        text-align: center;
        color: #1a1a1a;
        font-weight: 800;
        padding-top: 12px;
    }
    .timer{
        text-align: center;
        justify-content: center;
        margin: auto 6px;
    }
    .timer-text{
        font-size: .8rem;
        text-align: center;
        color: #1a1a1a;
        font-weight: 800;
        margin: 0;
    }
    .timer-item {
        border-radius: 16px;
        background-color: aliceblue;
        min-height: 5vh;
        height: fit-content;

        font-size: 2rem;
        text-align: center;
        color: red;
        font-weight: 800;
        padding: 0;
        margin: 0;
        animation: bump 1s ease-out infinite;
    }
    #no-time {
        color: #1a1a1a;
        animation: none;
    }
    .butClick {
        background-color: #1a1a1a;
        border: none;
        border-radius: 16px;
        color: #f0f0f0;
        padding: .9rem 1rem;
        margin-left: 16px;
        font-size: 1.2rem;
        cursor: pointer;
        opacity: 1;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.349);
    }
    .butClick:hover {
        color: red;
    }
    @media (max-width: 580px) {
        .butContent {
            padding: 24px 0 8px 0;
        }
    }
    @keyframes bump {
        0%, 100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
    }
</style>