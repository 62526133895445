import {createRouter, createWebHistory} from 'vue-router';

import LandingPage from '../views/LandingPage.vue';


const routes = [
    {
        path: "/", 
        component: LandingPage
    },
    {
        path: "/pre_aula", 
        component: () => import('../views/PreAula.vue'),
    },
    {
        path: "/politics", 
        component: () => import('../views/LandingPolitcs.vue'),
    },
    {
        path: "/terms", 
        component: () => import('../views/LandingTerms.vue'),
    },
    {
        path: "/aula_gratis", 
        component: () => import('../views/AulaGratis.vue'),
        beforeEnter: (to, from, next) => {
            const authUserValue = localStorage.getItem('authUser');
            const authPage = authUserValue === 'true';
            if(authPage == false) {
                window.alert('Preencha o formulário para acessar a próxima página.')
                console.log(`to ${to} from ${from}`)
                next('/pre_aula')
            } else {
                next()
            }  
        },
    },
    {
        path: "/pagina_obrigado",
        component: () => import('../views/PageThanks.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;