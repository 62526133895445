<template>
    <div>
        <section class="mainContent">
            <div class="introducao">
                    <div class="introducaoConteudo">
                        <div>
                            <h2>
                                Quem será seu mentor?
                            </h2>
                            <p>
                                Com mais de anos de mercado, Átila Vinicius iniciou a carreira por meio de problemas com acnes. Despertando assim sua paixão pela maquiagem e junto a isso seu desejo de ser tornar um profissional fora da curva para destacar-se do mercado de sua cidade.
                                Hoje tem como propósito formar o máximo de profissionais que buscam ser excelência e destaque no mercado.
                            </p>
                        </div>
                        <div>
                            <h2>
                                Embaixador Syll Gama
                            </h2>
        
                            <p>
                                Syll Gama, maquiadora, é uma das maiores referências de Natal-RN. Com sua influência criou a marca de maquiagem para atender com seu padrão de qualidade com produtos acessíveis.
                                Apresentado por uma aluna, Átila foi contatado para encontrar no workshop de maquiagem em Natal, onde foi ofertado a oportunidade de ser o primeiro embaixador da marca!
                            </p>
                        </div>
                    </div>
                <img class="imgAtila" src="./images/Foto-Atila-crop.png" alt="Foto átila">
            </div>
        </section>

        <h1 class="titleEvents">Participações em Eventos</h1>

        <a-carousel 
        autoplay
        dot-position=top>

            <div>
                <div class="containerEvents">
                    <div>
                        <h2>
                            Feira de beleza do Seridó
                        </h2>
                        <p>
                            A Feira de Beleza do Seridó, criada pela talentosa Valdenia Lima em parceria com a Agência Referência, é uma oportunidade imperdível para profissionais da beleza elevarem suas habilidades. Com acesso gratuito a especialistas de alto renome, tanto iniciantes como apaixonados pela beleza podem aprimorar suas técnicas. Como organizador do Concurso "Maquiador(a) Renomado" e palestrante do palco principal, compartilhei conhecimentos sobre posicionamento e criação de conteúdo, além de revelar truques preciosos de maquiagem.
                        </p>
                    </div>
                    <img class="imgEv" src="./images/banner-evento3.png" alt="beleza seridó">
                </div>
            </div>

            <div>
                <div class="containerEvents">
                    <div>
                        <h2>
                            O Amor Cura
                        </h2>
                        <p>
                            Workshop Beneficente "O Amor Cura", idealizado pela inspiradora Syll Gama. Seu projeto visionário oferece acesso gratuito ao conhecimento de profissionais renomados, proporcionando oportunidades únicas para aqueles que não podem participar de eventos pagos. Como um dos palestrantes desse evento inesquecível realizado no Hotel Golden Tulip, testemunhei a transformação de corações e almas sob a luz do dia 23 de abril. Junte-se a nós e compartilhe o poder do amor que cura!
                        </p>
                    </div>
                    <img class="imgEv" src="./images/banner-evento1.png" id="imgPink" alt="Amor cura
                    ">
                </div>
            </div>

            <div>
                <div class="containerEvents">
                    <div>
                        <h2>
                            Beauty Minds
                        </h2>
                        <p>
                            Um congresso de maquiagem idealizado pela talentosa Anairana Bezerra, fundadora da marca Anairana. Esse evento magnífico celebra a diversidade do universo da maquiagem e destaca os profissionais do nordeste do Brasil. Através de eventos, tive o prazer de conhecer o trabalho de Anairana e, como palestrante, fui honrado com a oportunidade de compartilhar minha expertise durante o evento realizado na encantadora cidade de Campina Grande, no BR Polo Shopping. 
                        </p>
                    </div>
                    <img class="imgEv" src="./images/banner-evento2.png" alt="beauty">
                </div>
            </div>
        </a-carousel>
    </div>

</template>

<script>
export default {

}

</script>

<style scoped>

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;800&display=swap');

    :deep(.slick-slide) {
        background-color: #B59F8A;
        overflow: hidden;
        padding-bottom: 1rem;
    }
    :deep(.slick-track) {
        background-color: #B59F8A;
        padding-top: 3rem;
    }

    .mainContent {
        padding: 3.5rem 1rem 0rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: linear-gradient( to bottom, #E3D5C8,#B59F8A );
    }

    .titleEvents {
        background-color: #B59F8A;
        margin-bottom: 0;
        text-align: center;
        color: #FFFFFF;
        padding-bottom: 2rem;
        font-weight: bold;
    }

    .introducao {
        display: flex;
        flex-direction: row;
    }

    .introducaoConteudo {
        display: flex;
        flex-direction: column;
        width: 35vw;
        gap: 5rem;
        margin-right: 5rem;
        text-align: justify;
    }

    .introducaoConteudo div > h2 {
        text-align: left;
        color: #394435;
        font-weight: bold;
    }

    .imgAtila {
        border-radius: 0px 0px 50px 0px;
        width: 35%;
        height: 70vh;
    }

    .containerEvents {
        margin: auto;
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .containerEvents div {
        border-radius: 10px 0px 0px 10px;
        background-color: #394435;
        padding: 3rem 1rem 0rem 1rem;
        text-align: left;
        color: #FFFFFF;
    }

    .containerEvents div p {
        min-height: 18rem;
    }

    .imgEv {
        width: 50%;
    }

    .containerEvents div h2 {
        text-align: left;
    }

    @media (max-width: 1010px) {
        .imgEv {
            width: 54%;
        }
    }


    @media (max-width: 840px) {

        .containerEvents div {
            border-radius: 10px 10px 0px 0px;
        }
        .containerEvents {
            flex-direction: column;
            width: 40%;
        }

        .imgEv {
            width: 100%;
        }
    }

    @media (max-width: 770px) {
        .imgAtila {
            width: 40%;
        }

        .containerEvents {
            flex-direction: column;
            width: 50%;
        }
    }

    @media (max-width: 600px) {
        .introducaoConteudo {
            margin-right: 1.5rem;
        }

        .imgAtila {
            width: 50%;
        }

        .containerEvents {
            flex-direction: column;
            width: 58%;
        }
    }

    @media (max-width: 540px) {
        .introducao {
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .introducaoConteudo {
            width: 60%;
            gap: 1rem;
            margin-right: 0rem;
        }

        .imgAtila {
            min-width: 55%;
            height: 60vh;
        }
    }

    @media (max-width: 490px) {

        .imgAtila {
            min-width: 60%;
        }
    }

    @media (max-width: 420px) {
        .introducaoConteudo {
            width: 75%;
            gap: 1rem;
            margin-bottom: 1rem;
        }
        .imgAtila {
            width: 75%;
        }

        .containerEvents {
            width: 80%;
        }
    }

    @media (max-width: 380px) {
        .imgAtila {
            width: 80%;
            height: 40vh;
        }

    }

    @media (max-height: 600px) {
        .imgAtila {
            height: 80vh;
        }
    }

</style>