<template>
        <a-col class="badgecard" :xs="20" :sm="18" :md="4" :lg="4" :xl="4">
            <a-row class="icon">
                <img id="img-icon" :src="iconPath" alt="icon">
            </a-row>
            <a-row class="text">
                <h3 id="title"> {{ title }} </h3>
                <p id="body"> {{ text }} </p>
            </a-row>
        </a-col>
</template>

<script>
    export default{
        props: {
            icon: String,
            text: String,
            title: String,
        },
        computed: {
            iconPath() {
            return require(`@/assets/icons/${this.icon}`);
            },
        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;800&display=swap');
    .badgecard {
        padding: 1vh 1vw;
        align-items: center;
        justify-content: center;
        color: #f0f0f0;
        height: fit-content;
        width: fit-content;
    }
    .icon {
        background-color: #394435;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 80px;
        margin: auto;
        /* filter: drop-shadow(-3px 5px 10px #000000); */
    }
    #img-icon {
        width: 48px;
        height: 48px;
    }
    #title {
        font-weight: 800;
        color: #394435;
        font-family: 'Montserrat', 'Courier New', Courier, monospace;
    }
    .text {
        /* border: 3px solid red; */
        padding: .5rem;
        margin-top: 1.5vh;
        min-height: 25vh;
        height: fit-content;
        width: fit-content;
        min-width: 140px;
        justify-content: center;
        text-align: center;
        color:#1a1a1a;
        background-color: #f0f0f0;
        border-radius: 1rem;
    }

    @media (max-width: 870px) {
        .badgecard {
            display: flex;
            flex-direction: row;
            background-color: #394435;
            border-radius: 16px;
            /* filter: drop-shadow(0px 1px 18px #ffffff3f); */
        }
        .icon {
            height: 56px;
            width: 56px;
        }
        #img-icon {
            width: 32px;
            height: 32px;
        }
        .text {
            color: #f0f0f0;
            min-height: 10vh;
            height: fit-content;
            padding: 1rem;
            justify-content: start;
            text-align: start;
            background-color: #394435;
        }
        #title {
            color: #f0f0f0;
            text-align: end;
        }
        
    }
</style>